.ant-tooltip.auto-width {
  max-width: max-content;
}

.ant-tooltip-inner {
  background-color: #313755 !important;
  color: #eaecf6 !important;
  font-weight: normal;
  border-radius: 12px !important;
  font-size: 14px;
  line-height: 1.7;
  padding: 8px 16px !important;
}
.ant-tooltip-inner .ant-tooltip-arrow {
  background-color: #262c4b;
}
.ant-tooltip-inner a {
  color: rgb(159, 153, 203);
  text-decoration: underline;
}
.ant-tooltip-inner a:hover {
  color: rgb(159, 153, 203);
}
.tooltip-wrap img {
  cursor: pointer;
  margin-left: 4px;
  margin-bottom: 2px;
}

.ant-tooltip.limit-order-tooltip .ant-tooltip-inner {
  padding: 8px 12px;
  color: rgba(245, 247, 251, 0.8) !important;
  font-weight: 400;
  font-size: 11px;
}
.ant-tooltip.limit-order-tooltip .ant-tooltip-arrow-content {
  width: 8px;
  height: 8px;
}

.ant-tooltip.spot-swap-settings-tooltip {
  z-index: 100;
}

.ant-tooltip.spot-swap-settings-tooltip .ant-tooltip-inner {
  width: 200px;
  padding: 8px 12px;
  color: rgba(245, 247, 251, 0.8) !important;
  font-weight: 400;
  font-size: 11px;
}
.ant-tooltip.spot-swap-settings-tooltip .ant-tooltip-arrow-content {
  width: 8px;
  height: 8px;
}

.ant-tooltip.spot-swap-tooltip .ant-tooltip-inner {
  max-width: 200px;
  padding: 8px 12px;
  color: rgba(245, 247, 251, 0.8) !important;
  font-weight: 400;
  font-size: 11px;
  white-space: pre-line;
}
.ant-tooltip.spot-swap-tooltip .ant-tooltip-arrow-content {
  width: 8px;
  height: 8px;
}

.ant-tooltip.spot-swap-on-max-tooltip .ant-tooltip-inner {
  max-width: 280px;
}

@media screen and (max-width: 640px) {
  .ant-tooltip-inner:lang(en) {
    font-size: 12px !important;
  }
  .ant-tooltip.auto-width {
    max-width: calc(100vw - 20px) !important;
  }
  .ant-tooltip.auto-width .ant-tooltip-inner {
    overflow: auto;
  }
  .ant-tooltip.spot-swap-settings-tooltip .ant-tooltip-arrow {
    left: 80%;
  }

  .ant-tooltip.lo-wrap-eth {
    left: auto !important;
    right: 15px !important;
  }

  .ant-tooltip.lo-wrap-eth .ant-tooltip-arrow {
    left: 85%;
  }

  .ant-tooltip.spot-swap-on-max-tooltip,
  .ant-tooltip.spot-swap-settings-tooltip {
    left: auto !important;
    right: 20px !important;
  }
}
